import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { qrCodeApi, type QRCode, type CreateQRCodeRequest } from '@/api/qrCode';
import { ref } from 'vue';

// Query keys
const QR_CODES_KEY = ['qrCodes'];

// Valid shape types
const VALID_SHAPES = [
    'square',
    'circle',
    'diamond',
    'dot',
    'star'
];

export function useQrCodes() {
    const queryClient = useQueryClient();
    const error = ref<string | null>(null);
    const previewUrl = ref<string | null>(null);

    // Query for fetching QR codes
    const { data: qrCodes, isLoading } = useQuery({
        queryKey: QR_CODES_KEY,
        queryFn: qrCodeApi.fetchQrCodes,
    });

    // Mutation for creating QR codes
    const createMutation = useMutation({
        mutationFn: qrCodeApi.createQrCode,
        onSuccess: (newQrCode) => {
            // Update the cache with the new QR code at the beginning of the list
            queryClient.setQueryData<QRCode[]>(QR_CODES_KEY, (oldData) => {
                return oldData ? [newQrCode, ...oldData] : [newQrCode];
            });
            error.value = null;
        },
        onError: (err: Error) => {
            console.error('Failed to create QR code:', err);
            error.value = 'Failed to create QR code';
        },
    });

    // Mutation for deleting QR codes
    const deleteMutation = useMutation({
        mutationFn: qrCodeApi.deleteQrCode,
        onSuccess: (_, deletedId) => {
            // Update the cache by removing the deleted QR code
            queryClient.setQueryData<QRCode[]>(QR_CODES_KEY, (oldData) => {
                return oldData ? oldData.filter((qr) => qr.id !== deletedId) : [];
            });
            error.value = null;
        },
        onError: (err: Error) => {
            console.error('Failed to delete QR code:', err);
            error.value = 'Failed to delete QR code';
        },
    });

    // Mutation for downloading QR codes
    const downloadMutation = useMutation({
        mutationFn: qrCodeApi.downloadQrCode,
        onError: (err: Error) => {
            console.error('Failed to download QR code:', err);
            error.value = 'Failed to download QR code';
        },
    });

    // Function to update QR code counts from WebSocket data
    const updateQrCodeCounts = (updates: { id: string; openCount: number }[]) => {
        queryClient.setQueryData<QRCode[]>(QR_CODES_KEY, (oldData) => {
            if (!oldData) return oldData;
            return oldData.map((qrCode) => {
                const update = updates.find((u) => u.id === qrCode.id);
                return update ? { ...qrCode, openCount: update.openCount } : qrCode;
            });
        });
    };

    const generateQrCode = async (request: CreateQRCodeRequest) => {
        if (!request.url.trim()) {
            error.value = 'URL cannot be empty.';
            throw new Error('URL cannot be empty.');
        }

        // Validate color formats if provided
        const hexColorRegex = /^#[0-9A-Fa-f]{6}$/;
        if (request.fgColor && !hexColorRegex.test(request.fgColor)) {
            error.value = 'Invalid foreground color format. Must be a valid hex color (e.g., #000000)';
            throw new Error('Invalid foreground color format');
        }
        if (request.bgColor && !hexColorRegex.test(request.bgColor)) {
            error.value = 'Invalid background color format. Must be a valid hex color (e.g., #FFFFFF)';
            throw new Error('Invalid background color format');
        }

        // Validate shape if provided
        if (request.shape && !VALID_SHAPES.includes(request.shape)) {
            error.value = 'Invalid shape type. Must be one of: square, circle, diamond, dot, star';
            throw new Error('Invalid shape type');
        }

        // Validate shape size if provided
        if (request.shapeSize !== undefined && (request.shapeSize < 0 || request.shapeSize > 1)) {
            error.value = 'Invalid shape size. Must be between 0.0 and 1.0';
            throw new Error('Invalid shape size');
        }

        // Validate error correction level if provided
        const validErrorLevels = ['L', 'M', 'Q', 'H'];
        if (request.errorCorrectionLevel && !validErrorLevels.includes(request.errorCorrectionLevel)) {
            error.value = 'Invalid error correction level. Must be one of: L, M, Q, H';
            throw new Error('Invalid error correction level');
        }

        return createMutation.mutateAsync(request);
    };

    const previewQrCode = async (request: CreateQRCodeRequest) => {
        try {
            const blob = await qrCodeApi.previewQrCode(request);
            if (previewUrl.value) {
                URL.revokeObjectURL(previewUrl.value);
            }
            previewUrl.value = URL.createObjectURL(blob);
            error.value = null;
        } catch (err) {
            console.error('Failed to preview QR code:', err);
            error.value = 'Failed to preview QR code';
            previewUrl.value = null;
        }
    };

    return {
        qrCodes,
        isLoading,
        error,
        previewUrl,
        generateQrCode,
        deleteQrCode: deleteMutation.mutate,
        downloadQrCode: downloadMutation.mutate,
        updateQrCodeCounts,
        previewQrCode
    };
}

export function useQrCode() {
    const {
        data: qrCodes,
        isLoading,
        error,
    } = useQuery({
        queryKey: ['qrCodes'],
        queryFn: qrCodeApi.fetchQrCodes,
    });

    return {
        qrCodes,
        isLoading,
        error,
    };
} 