<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useAuth } from '@/composables/useAuth';
import { RouterLink } from 'vue-router';

interface FieldError {
  field: string;
  message: string;
}

const fullName = ref<string>('');
const email = ref<string>('');
const password = ref<string>('');
const showPassword = ref<boolean>(false);
const fieldErrors = ref<FieldError[]>([]);
const { register, error, registrationSuccess, isLoading } = useAuth();

const validateForm = (): boolean => {
  fieldErrors.value = [];
  let isValid = true;

  // Validate full name
  if (!fullName.value) {
    fieldErrors.value.push({ field: 'fullName', message: 'Full name is required' });
    isValid = false;
  } else if (fullName.value.length < 2) {
    fieldErrors.value.push({ field: 'fullName', message: 'Full name must be at least 2 characters long' });
    isValid = false;
  } else if (fullName.value.length > 100) {
    fieldErrors.value.push({ field: 'fullName', message: 'Full name cannot be longer than 100 characters' });
    isValid = false;
  } else if (!/^[\p{L}\p{N}\s]+$/u.test(fullName.value)) {
    fieldErrors.value.push({ field: 'fullName', message: 'Full name can only contain letters, numbers, and spaces' });
    isValid = false;
  }

  // Validate email
  if (!email.value) {
    fieldErrors.value.push({ field: 'email', message: 'Email is required' });
    isValid = false;
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value)) {
    fieldErrors.value.push({ field: 'email', message: 'Please enter a valid email address' });
    isValid = false;
  }

  // Validate password
  if (!password.value) {
    fieldErrors.value.push({ field: 'password', message: 'Password is required' });
    isValid = false;
  } else if (password.value.length < 8) {
    fieldErrors.value.push({ field: 'password', message: 'Password must be at least 8 characters long' });
    isValid = false;
  }

  return isValid;
};

const getFieldError = (fieldName: string) => {
  return fieldErrors.value.find(err => err.field === fieldName)?.message;
};

const hasFieldError = (fieldName: string) => {
  return !!getFieldError(fieldName);
};

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

const handleRegister = async () => {
  try {
    fieldErrors.value = []; // Clear any previous errors

    // Validate form before submitting
    if (!validateForm()) {
      return;
    }

    await register(fullName.value, email.value, password.value);
  } catch (err: any) {
    console.error('Registration error:', err);
    if (err.validationErrors) {
      // Map any 'name' field errors to 'fullName'
      fieldErrors.value = err.validationErrors.map((error: FieldError) => ({
        ...error,
        field: error.field === 'name' ? 'fullName' : error.field
      }));
    }
  }
};

const getInputClass = (fieldName: string) => {
  return `block w-full pl-10 pr-${fieldName === 'password' ? '10' : '3'} py-2 border ${hasFieldError(fieldName)
    ? 'border-red-500 dark:border-red-500'
    : 'border-gray-300 dark:border-gray-600'
    } rounded-lg focus:ring-2 ${hasFieldError(fieldName)
      ? 'focus:ring-red-500 focus:border-red-500'
      : 'focus:ring-blue-500 focus:border-blue-500'
    } text-gray-900 dark:text-white dark:bg-gray-700 placeholder-gray-500 dark:placeholder-gray-400`;
};

const calculatePasswordStrength = (pwd: string): string => {
  if (!pwd) return 'none';

  let score = 0;
  // Length check
  if (pwd.length >= 8) score++;
  // Contains number
  if (/\d/.test(pwd)) score++;
  // Contains lowercase
  if (/[a-z]/.test(pwd)) score++;
  // Contains uppercase
  if (/[A-Z]/.test(pwd)) score++;
  // Contains special char
  if (/[^A-Za-z0-9]/.test(pwd)) score++;

  if (score === 0) return 'none';
  if (score <= 2) return 'weak';
  if (score <= 4) return 'medium';
  return 'strong';
};

const passwordStrength = computed(() => calculatePasswordStrength(password.value));
</script>

<template>
  <main
    class="min-h-[calc(100vh-4rem)] flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800 py-8 px-4">
    <div class="w-full max-w-md bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg space-y-6">
      <div class="text-center">
        <h1 class="text-3xl font-bold text-gray-900 dark:text-white mb-2">Create Account</h1>
        <p class="text-gray-600 dark:text-gray-300">Join us to start managing your QR codes</p>
      </div>

      <div v-if="error && !fieldErrors.length"
        class="mb-4 p-4 bg-red-50 dark:bg-red-900/50 border-l-4 border-red-500 text-red-700 dark:text-red-200 rounded-r"
        role="alert" data-test="error-message">
        <div class="flex items-center">
          <svg class="h-5 w-5 mr-2 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clip-rule="evenodd" />
          </svg>
          {{ error }}
        </div>
      </div>

      <div v-if="registrationSuccess"
        class="mb-4 p-4 bg-green-50 dark:bg-green-900/50 border-l-4 border-green-500 text-green-700 dark:text-green-200 rounded-r"
        data-test="success-message">
        <p class="flex items-center">
          <svg class="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          Your account has been created. Please check your email to confirm your registration.
        </p>
      </div>

      <form v-else class="space-y-6" @submit.prevent="handleRegister" data-test="register-form">
        <div>
          <label for="fullName" class="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Full
            Name</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5"
                :class="hasFieldError('fullName') ? 'text-red-400' : 'text-gray-400 dark:text-gray-500'" fill="none"
                stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
            </div>
            <input id="fullName" type="text" name="fullName" v-model="fullName" :disabled="isLoading"
              :class="getInputClass('fullName')" placeholder="John Doe" data-test="fullname-input" required />
          </div>
          <p v-if="getFieldError('fullName')" class="mt-1 text-sm text-red-600 dark:text-red-400" role="alert"
            data-test="fullName-error">
            {{ getFieldError('fullName') }}
          </p>
        </div>

        <div>
          <label for="email" class="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Email
            Address</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5" :class="hasFieldError('email') ? 'text-red-400' : 'text-gray-400 dark:text-gray-500'"
                fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
              </svg>
            </div>
            <input id="email" type="text" name="email" v-model="email" :disabled="isLoading"
              :class="getInputClass('email')" placeholder="you@example.com" autocomplete="email" data-test="email-input"
              required />
          </div>
          <p v-if="getFieldError('email')" class="mt-1 text-sm text-red-600 dark:text-red-400" role="alert"
            data-test="email-error">
            {{ getFieldError('email') }}
          </p>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Password</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5"
                :class="hasFieldError('password') ? 'text-red-400' : 'text-gray-400 dark:text-gray-500'" fill="none"
                stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
              </svg>
            </div>
            <input id="password" :type="showPassword ? 'text' : 'password'" name="password" v-model="password"
              :disabled="isLoading" :class="getInputClass('password')" placeholder="••••••••"
              autocomplete="new-password" data-test="password-input" required />
            <button type="button" @click="togglePasswordVisibility"
              class="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300 focus:outline-none"
              data-test="toggle-password">
              <svg v-if="showPassword" class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
              </svg>
              <svg v-else class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
              </svg>
            </button>
          </div>
          <p v-if="getFieldError('password')" class="mt-1 text-sm text-red-600 dark:text-red-400" role="alert"
            data-test="password-error">
            {{ getFieldError('password') }}
          </p>
          <div v-if="password" class="mt-1" data-test="password-strength">
            <div class="text-sm text-gray-600 dark:text-gray-400">Password strength: {{ passwordStrength }}</div>
            <div class="mt-1 h-1 w-full bg-gray-200 dark:bg-gray-700 rounded">
              <div class="h-1 rounded transition-all duration-300" :class="{
                'w-1/3 bg-red-500': passwordStrength === 'weak',
                'w-2/3 bg-yellow-500': passwordStrength === 'medium',
                'w-full bg-green-500': passwordStrength === 'strong'
              }"></div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="text-sm">
            <RouterLink to="/login"
              class="font-medium text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300"
              data-test="login-link">
              Already have an account?
            </RouterLink>
          </div>
        </div>

        <button type="submit"
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 dark:bg-blue-500 hover:bg-blue-700 dark:hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-offset-gray-800 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
          :disabled="isLoading" data-test="submit-button">
          <svg v-if="isLoading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
          </svg>
          <span>{{ isLoading ? 'Creating account...' : 'Create account' }}</span>
        </button>
      </form>
    </div>
  </main>
</template>
