import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";
import { ref } from 'vue';
import { AxiosError } from 'axios';

export function useAuth() {
    const authStore = useAuthStore();
    const router = useRouter();
    const error = ref<string | null>(null);
    const registrationSuccess = ref(false);
    const isLoading = ref(false);

    const reset = () => {
        error.value = null;
        registrationSuccess.value = false;
    }

    const getErrorMessage = (err: unknown): string => {
        if (err instanceof AxiosError) {
            return err.response?.data?.message || err.message || 'An error occurred';
        }
        return err instanceof Error ? err.message : 'An error occurred';
    }

    const login = async (email: string, password: string) => {
        try {
            reset(); // Reset error before attempting login
            isLoading.value = true;
            await authStore.login(email, password);
            // Only navigate on success
            await router.push('/dashboard');
        } catch (err) {
            if (err instanceof Error) {
                error.value = err.message;
            } else {
                error.value = 'An unexpected error occurred';
            }
            // Stay on login page with error message
        } finally {
            isLoading.value = false;
        }
    };

    const register = async (fullName: string, email: string, password: string) => {
        try {
            reset(); // Reset error before attempting registration
            isLoading.value = true;
            const result = await authStore.register(fullName, email, password);
            registrationSuccess.value = true;
            return result;
        } catch (err: any) {
            console.error('Registration failed:', err);
            if (err.validationErrors) {
                // If it's a validation error, pass it through
                throw err;
            }
            error.value = getErrorMessage(err);
            throw err;
        } finally {
            isLoading.value = false;
        }
    };

    const logout = async () => {
        try {
            reset(); // Reset error before attempting logout
            isLoading.value = true;
            await authStore.logout();
            await router.push('/login');
        } catch (err) {
            console.error('Logout failed:', err);
            error.value = getErrorMessage(err);
        } finally {
            isLoading.value = false;
        }
    };

    return {
        login,
        register,
        logout,
        error,
        registrationSuccess,
        isLoading
    };
}
