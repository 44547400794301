import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import HomePage from '@/pages/HomePage.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import DashboardPage from '@/pages/DashboardPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import RegisterPage from '@/pages/RegisterPage.vue';
import FeaturesComparison from '@/components/FeaturesComparison.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage,
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/pages/AboutPage.vue'),
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: () => import('@/pages/PricingPage.vue'),
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import('@/pages/TermsPage.vue'),
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/pages/PrivacyPage.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterPage,
    },
    {
        path: '/verify-email',
        name: 'verify-email',
        component: () => import('@/pages/VerifyEmailPage.vue'),
    },
    {
        path: '/request-reset',
        name: 'request-reset',
        component: () => import('@/pages/RequestPasswordResetPage.vue'),
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/pages/ResetPasswordPage.vue'),
    },
    {
        path: '/dashboard',
        component: DashboardLayout,
        children: [{
            path: '',
            name: 'dashboard',
            component: DashboardPage
        }, {
            path: 'qr-codes',
            component: () => import('@/pages/QrCodeManagementPage.vue')
        }],
        meta: { requiresAuth: true },
    },
    {
        path: '/features',
        name: 'features',
        component: FeaturesComparison,
        meta: {
            title: 'Features Comparison'
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/pages/ContactPage.vue')
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

// Navigation guard to check for authentication
router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();

    if (to.meta.requiresAuth && !authStore.token) {
        next({ name: 'login' });
    } else {
        next();
    }
});

export default router;
