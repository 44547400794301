import axios from '@/plugins/axios';

export interface QRCode {
    id: string;
    userId: string;
    url: string;
    openCount: number;
    createdAt: string;
    updatedAt: string;
    fgColor?: string;
    bgColor?: string;
    shape?: 'square' | 'circle' | 'diamond' | 'dot' | 'star';
    shapeSize?: number;
    finderShape?: 'square' | 'circle' | 'diamond' | 'dot' | 'star';
    finderSize?: number;
    errorCorrectionLevel?: 'L' | 'M' | 'Q' | 'H';
    enableTracking: boolean;
}

export interface CreateQRCodeRequest {
    url: string;
    fgColor?: string;
    bgColor?: string;
    shape?: 'square' | 'circle' | 'diamond' | 'dot' | 'star';
    shapeSize?: number;
    finderShape?: 'square' | 'circle' | 'diamond' | 'dot' | 'star';
    finderSize?: number;
    errorCorrectionLevel?: 'L' | 'M' | 'Q' | 'H';
    enableTracking?: boolean;
}

export const qrCodeApi = {
    fetchQrCodes: async (): Promise<QRCode[]> => {
        const response = await axios.get('/protected/qr-codes');
        return response.data.map((qrCode: any) => ({
            id: qrCode.id,
            userId: qrCode.userId,
            url: qrCode.url,
            openCount: qrCode.openCount || 0,
            createdAt: qrCode.createdAt,
            updatedAt: qrCode.updatedAt,
            fgColor: qrCode.fgColor,
            bgColor: qrCode.bgColor,
            shape: qrCode.shape,
            shapeSize: qrCode.shapeSize,
            finderShape: qrCode.finderShape,
            finderSize: qrCode.finderSize,
            errorCorrectionLevel: qrCode.errorCorrectionLevel,
            enableTracking: qrCode.enableTracking || false
        }));
    },

    createQrCode: async (request: CreateQRCodeRequest): Promise<QRCode> => {
        const response = await axios.post('/protected/qr-codes', request);
        return {
            id: response.data.id,
            userId: response.data.userId,
            url: response.data.url,
            openCount: response.data.openCount || 0,
            createdAt: response.data.createdAt,
            updatedAt: response.data.updatedAt,
            fgColor: response.data.fgColor,
            bgColor: response.data.bgColor,
            shape: response.data.shape,
            shapeSize: response.data.shapeSize,
            finderShape: response.data.finderShape,
            finderSize: response.data.finderSize,
            errorCorrectionLevel: response.data.errorCorrectionLevel,
            enableTracking: response.data.enableTracking || false
        };
    },

    deleteQrCode: async (id: string): Promise<void> => {
        await axios.delete(`/protected/qr-codes/${id}`);
    },

    downloadQrCode: async (id: string): Promise<void> => {
        const response = await axios.get(`/qr-codes/${id}/image`, {
            responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `qr-code-${id}.png`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    },

    getQrCodeImageUrl: (id: string): string => {
        return `/api/qr-codes/${id}/image`;
    },

    previewQrCode: async (request: CreateQRCodeRequest): Promise<Blob> => {
        try {
            const response = await axios.post(
                '/protected/qr-codes/preview',
                request,
                {
                    responseType: 'blob',
                }
            );
            return response.data;
        } catch (error) {
            console.error('Failed to preview QR code:', error);
            throw new Error('Failed to preview QR code');
        }
    }
}; 