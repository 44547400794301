<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth';

const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();
const showMobileMenu = ref(false);
const isScrolled = ref(false);

const handleScroll = () => {
    isScrolled.value = window.scrollY > 0;
};

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});

const toggleMobileMenu = () => {
    showMobileMenu.value = !showMobileMenu.value;
};

const closeMobileMenu = () => {
    showMobileMenu.value = false;
};

const logout = async () => {
    await authStore.logout();
    await router.push('/login');
};
</script>

<template>
    <header
        class="fixed top-0 left-0 right-0 z-50 bg-blue-600/90 dark:bg-gray-800/90 text-white backdrop-blur-sm transition-all duration-200"
        :class="{ 'shadow-md': isScrolled }">
        <div class="container mx-auto px-4">
            <div class="flex justify-between items-center h-16">
                <!-- Logo/Brand -->
                <RouterLink to="/" class="text-xl font-bold" @click="closeMobileMenu">FlowPact</RouterLink>

                <!-- Mobile menu button -->
                <button @click="toggleMobileMenu"
                    class="md:hidden p-2 rounded-md hover:bg-blue-700 dark:hover:bg-gray-700 focus:outline-none">
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path v-if="showMobileMenu" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                        <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>

                <!-- Desktop Navigation -->
                <nav class="hidden md:flex items-center space-x-4">
                    <RouterLink to="/" class="nav-link" :class="{ 'active': route.path === '/' }">Home</RouterLink>
                    <RouterLink to="/pricing" class="nav-link" :class="{ 'active': route.path === '/pricing' }">Pricing
                    </RouterLink>
                    <template v-if="!authStore.isAuthenticated">
                        <RouterLink to="/login" class="nav-link" :class="{ 'active': route.path === '/login' }">Log in
                        </RouterLink>
                        <RouterLink to="/register"
                            class="nav-link bg-white dark:bg-gray-700 text-blue-600 dark:text-white hover:bg-blue-50 dark:hover:bg-gray-600"
                            :class="{ 'active': route.path === '/register' }">
                            Start Free Trial
                        </RouterLink>
                    </template>
                    <template v-else>
                        <RouterLink to="/dashboard" class="nav-link" :class="{ 'active': route.path === '/dashboard' }">
                            Dashboard
                        </RouterLink>
                        <RouterLink to="/dashboard/qr-codes" class="nav-link"
                            :class="{ 'active': route.path.includes('/dashboard/qr') }">
                            QR Codes
                        </RouterLink>
                        <button @click="logout" class="nav-link" data-test="logout-button">Logout</button>
                    </template>
                </nav>

                <!-- User Profile (Desktop) -->
                <div v-if="authStore.isAuthenticated" class="hidden md:flex items-center space-x-4">
                    <div class="flex items-center space-x-2">
                        <div class="w-8 h-8 rounded-full bg-blue-700 dark:bg-gray-700 flex items-center justify-center">
                            {{ authStore.userName.charAt(0).toUpperCase() }}
                        </div>
                        <span class="text-sm">{{ authStore.userName }}</span>
                    </div>
                </div>
            </div>

            <!-- Mobile Navigation -->
            <transition enter-active-class="transition-all ease-out duration-200"
                enter-from-class="opacity-0 -translate-y-4" enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition-all ease-in duration-150" leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 -translate-y-4">
                <div v-if="showMobileMenu" class="md:hidden py-4 bg-blue-600/90 dark:bg-gray-800/90 backdrop-blur-sm">
                    <div class="flex flex-col space-y-2">
                        <RouterLink to="/" class="mobile-nav-link" @click="closeMobileMenu">Home</RouterLink>
                        <RouterLink to="/pricing" class="mobile-nav-link" @click="closeMobileMenu">Pricing</RouterLink>
                        <template v-if="!authStore.isAuthenticated">
                            <RouterLink to="/login" class="mobile-nav-link" @click="closeMobileMenu">Log in</RouterLink>
                            <RouterLink to="/register" class="mobile-nav-link" @click="closeMobileMenu">Start Free Trial
                            </RouterLink>
                        </template>
                        <template v-else>
                            <!-- User Profile (Mobile) -->
                            <div class="flex items-center space-x-2 px-4 py-2">
                                <div
                                    class="w-8 h-8 rounded-full bg-blue-700 dark:bg-gray-700 flex items-center justify-center">
                                    {{ authStore.userName.charAt(0).toUpperCase() }}
                                </div>
                                <span class="text-sm">{{ authStore.userName }}</span>
                            </div>
                            <RouterLink to="/dashboard" class="mobile-nav-link" @click="closeMobileMenu">Dashboard
                            </RouterLink>
                            <RouterLink to="/dashboard/qr-codes" class="mobile-nav-link" @click="closeMobileMenu">
                                QR Codes
                            </RouterLink>
                            <button @click="logout" class="mobile-nav-link text-left"
                                data-test="logout-button-mobile">Logout</button>
                        </template>
                    </div>
                </div>
            </transition>
        </div>
    </header>
</template>

<style scoped>
.nav-link {
    @apply px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200;
    @apply hover:bg-blue-700 dark:hover:bg-gray-700;
}

.nav-link.active {
    @apply bg-blue-700 dark:bg-gray-700;
}

.mobile-nav-link {
    @apply block px-4 py-2 text-base font-medium hover:bg-blue-700 dark:hover:bg-gray-700 transition-colors duration-200;
}

/* Click outside directive styles */
.v-click-outside {
    position: relative;
}

/* Add padding to the body to account for fixed header */
:global(body) {
    padding-top: 4rem;
}
</style>