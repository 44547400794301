import { VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query';
import type { App } from 'vue';

export function setupVueQuery(app: App) {
    const vueQueryPluginOptions: VueQueryPluginOptions = {
        queryClientConfig: {
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    retry: 1,
                    staleTime: 5 * 60 * 1000, // Data remains fresh for 5 minutes
                    gcTime: 10 * 60 * 1000, // Cache persists for 10 minutes
                },
            },
        },
    };

    app.use(VueQueryPlugin, vueQueryPluginOptions);
} 