<!-- src/pages/Dashboard.vue -->
<template>
  <div class="min-h-screen bg-gray-50 dark:bg-gray-900 py-8 transition-colors duration-200">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="mb-8">
        <h1 class="text-3xl font-bold text-gray-900 dark:text-white">Dashboard</h1>
        <p class="mt-2 text-lg text-gray-600 dark:text-gray-300">Welcome back, {{ userName }}</p>
      </div>

      <!-- Stats Grid -->
      <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <!-- Total QR Codes -->
        <div class="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg transition-colors duration-200"
          data-test="stats-total-qr-codes">
          <div class="p-5">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400 dark:text-gray-500" fill="none" stroke="currentColor"
                  viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" />
                </svg>
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">Total QR Codes</dt>
                  <dd class="flex items-baseline">
                    <div v-if="isLoading" class="animate-pulse bg-gray-200 dark:bg-gray-700 h-8 w-16 rounded"></div>
                    <div v-else class="text-2xl font-semibold text-gray-900 dark:text-white">{{ totalQrCodes }}</div>
                    <span class="sr-only">QR codes</span>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 dark:bg-gray-700 px-5 py-3">
            <div class="text-sm">
              <RouterLink to="/dashboard/qr-codes" data-test="manage-qr-link"
                class="font-medium text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300">
                <span>Manage QR Codes</span>
                <span class="sr-only">Create, view and manage your QR codes</span>
              </RouterLink>
            </div>
          </div>
        </div>

        <!-- Total Scans -->
        <div class="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg transition-colors duration-200"
          data-test="stats-total-scans">
          <div class="p-5">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400 dark:text-gray-500" fill="none" stroke="currentColor"
                  viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">Total Scans</dt>
                  <dd class="flex items-baseline">
                    <div v-if="isLoading" class="animate-pulse bg-gray-200 dark:bg-gray-700 h-8 w-16 rounded"></div>
                    <div v-else class="text-2xl font-semibold text-gray-900 dark:text-white">{{ totalScans }}</div>
                    <span class="sr-only">of scans</span>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 dark:bg-gray-700 px-5 py-3">
            <div class="text-sm">
              <RouterLink to="/dashboard/analytics"
                class="font-medium text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300">
                <span>View analytics</span>
                <span class="sr-only">View detailed analytics and statistics</span>
              </RouterLink>
            </div>
          </div>
        </div>

        <!-- Recent Activity -->
        <div class="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg transition-colors duration-200"
          data-test="stats-recent-activity">
          <div class="p-5">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400 dark:text-gray-500" fill="none" stroke="currentColor"
                  viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt class="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">Recent Activity</dt>
                  <dd class="flex items-baseline">
                    <div v-if="isLoading" class="animate-pulse bg-gray-200 dark:bg-gray-700 h-8 w-16 rounded"></div>
                    <div v-else class="text-2xl font-semibold text-gray-900 dark:text-white">{{ recentActivityCount }}
                    </div>
                    <span class="ml-2 text-sm text-gray-500 dark:text-gray-400">in the last 24h</span>
                    <span class="sr-only">details</span>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 dark:bg-gray-700 px-5 py-3">
            <div class="text-sm">
              <RouterLink to="/dashboard/activity"
                class="font-medium text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300">
                <span>View activity</span>
                <span class="sr-only">View recent QR code activity</span>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>

      <!-- Quick Actions -->
      <div class="mt-8">
        <h2 class="text-lg font-medium text-gray-900 dark:text-white mb-4">Quick Actions</h2>
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          <RouterLink to="/dashboard/qr-codes" data-test="create-qr-link"
            class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 dark:border-gray-600 p-12 text-center hover:border-gray-400 dark:hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            <svg class="mx-auto h-12 w-12 text-gray-400 dark:text-gray-500" fill="none" stroke="currentColor"
              viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
            </svg>
            <span class="mt-2 block text-sm font-medium text-gray-900 dark:text-white">Create New QR Code</span>
            <span class="sr-only">Create a new QR code</span>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { useQrCode } from '@/composables/useQrCode'
import { computed } from 'vue'

defineOptions({
  name: 'DashboardPage'
})

const authStore = useAuthStore()
const { qrCodes, isLoading } = useQrCode()

const userName = computed(() => authStore.user?.name || 'Guest')
const totalQrCodes = computed(() => qrCodes.value?.length ?? 0)
const totalScans = computed(() => qrCodes.value?.reduce((sum, code) => sum + code.openCount, 0) ?? 0)
const recentActivityCount = computed(() => {
  const last24h = new Date(Date.now() - 24 * 60 * 60 * 1000)
  return qrCodes.value?.filter(code => new Date(code.updatedAt) > last24h).length ?? 0
})
</script>

<style scoped>
/* Additional scoped styles if needed */
</style>
