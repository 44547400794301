<script setup lang="ts">
import SiteFooter from '@/components/Footer.vue';
import SiteNavigation from '@/components/SiteNavigation.vue';
import { useThemeStore } from '@/stores/theme';

// Initialize theme store
useThemeStore();
</script>

<template>
  <div class="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col transition-colors duration-200">
    <SiteNavigation />
    <main class="container mx-auto p-4 flex-grow dark:text-gray-100">
      <RouterView />
    </main>
    <SiteFooter />
  </div>
</template>

<style scoped>
/* Click outside directive styles */
.v-click-outside {
  position: relative;
}
</style>
