import { defineStore } from 'pinia'
import axios from '@/plugins/axios'
import { jwtDecode } from 'jwt-decode'
import { ref, computed } from 'vue'

interface User {
    name: string;
    id: string;
    isVerified: boolean;
}

interface JWTPayload {
    exp: number;
    name: string;
    user_id: string;
    is_verified: boolean;
    iat: number;
}

interface ValidationError {
    field: string;
    message: string;
}

export const useAuthStore = defineStore('auth', () => {
    const token = ref<string | null>(null)
    const refreshToken = ref<string | null>(null)
    const user = ref<User | null>(null)
    let refreshTimer: number | null = null

    const isAuthenticated = computed(() => {
        return !!token.value
    })

    const userName = computed(() => {
        return user.value?.name || 'Guest'
    })

    function updateUserFromToken(accessToken: string) {
        try {
            const decoded = jwtDecode<JWTPayload>(accessToken)
            console.log('Decoded token:', decoded)
            user.value = {
                name: decoded.name,
                id: decoded.user_id,
                isVerified: decoded.is_verified
            }
            console.log('Updated user:', user.value)
        } catch (error) {
            console.error('Failed to decode user from token:', error)
            user.value = null
        }
    }

    function startRefreshTimer() {
        if (refreshTimer) {
            window.clearTimeout(refreshTimer)
        }

        if (!token.value) return

        try {
            const decoded = jwtDecode<JWTPayload>(token.value)
            const expiresAt = decoded.exp
            const now = Date.now() / 1000
            const timeUntilExpiry = expiresAt - now

            // Refresh when 75% of the token's lifetime has passed
            const refreshAt = timeUntilExpiry * 0.25

            refreshTimer = window.setTimeout(async () => {
                await refreshAccessToken()
            }, refreshAt * 1000) // Convert to milliseconds
        } catch (error) {
            // Just clear tokens without throwing an error
            clearTokens()
        }
    }

    function setTokens(accessToken: string, newRefreshToken: string) {
        token.value = accessToken
        refreshToken.value = newRefreshToken
        localStorage.setItem('token', accessToken)
        localStorage.setItem('refresh_token', newRefreshToken)
        updateUserFromToken(accessToken)
        startRefreshTimer() // Start the refresh timer when new tokens are set
    }

    function clearTokens() {
        token.value = null
        refreshToken.value = null
        user.value = null
        localStorage.removeItem('token')
        localStorage.removeItem('refresh_token')
        if (refreshTimer) {
            window.clearTimeout(refreshTimer)
            refreshTimer = null
        }
    }

    async function register(fullName: string, email: string, password: string) {
        try {
            const response = await axios.post('/auth/register', {
                name: fullName,
                email,
                password
            })
            if (response.data.message) {
                return { success: true, message: response.data.message }
            } else {
                throw new Error('Invalid response from server')
            }
        } catch (error: any) {
            if (error.response?.data?.details) {
                // Map validation errors to more user-friendly messages
                const validationErrors: ValidationError[] = error.response.data.details
                    .map((detail: { field: string; message: string }) => {
                        const field = detail.field.toLowerCase()
                        const msg = detail.message.toLowerCase()
                        let message = ''

                        // Map common validation errors to user-friendly messages
                        if (field === 'email') {
                            if (msg.includes('email')) {
                                message = 'Please enter a valid email address'
                            } else if (msg.includes('required')) {
                                message = 'Email address is required'
                            }
                        } else if (field === 'password') {
                            if (msg.includes('min')) {
                                message = 'Password must be at least 8 characters long'
                            } else if (msg.includes('required')) {
                                message = 'Password is required'
                            } else if (msg.includes('max')) {
                                message = 'Password cannot be longer than 100 characters'
                            }
                        } else if (field === 'name') {
                            if (msg.includes('required')) {
                                message = 'Full name is required'
                            } else if (msg.includes('min')) {
                                message = 'Full name must be at least 2 characters long'
                            } else if (msg.includes('max')) {
                                message = 'Full name cannot be longer than 100 characters'
                            } else if (msg.includes('alpha')) {
                                message = 'Full name can only contain letters and spaces'
                            }
                        }

                        // If no specific mapping exists, make the message more readable
                        if (!message) {
                            message = detail.message.charAt(0).toUpperCase() + detail.message.slice(1)
                        }

                        return {
                            field: field === 'name' ? 'fullName' : field,
                            message
                        }
                    })
                throw { validationErrors }
            } else if (error.response?.data?.message) {
                throw new Error(error.response.data.message)
            } else {
                throw error
            }
        }
    }

    async function login(email: string, password: string) {
        try {
            const response = await axios.post('/auth/login', { email, password })
            if (response.data.token && response.data.refresh_token) {
                setTokens(response.data.token, response.data.refresh_token)
            } else {
                throw new Error('Invalid response from server')
            }
        } catch (error: any) {
            if (error.response?.data?.message) {
                if (error.response.data.message === 'Email not verified') {
                    throw new Error('Please verify your email before logging in')
                }
                throw new Error(error.response.data.message)
            } else if (error.response?.status === 401) {
                throw new Error('Invalid credentials')
            } else {
                throw error
            }
        }
    }

    async function refreshAccessToken() {
        if (!refreshToken.value) {
            clearTokens()
            throw new Error('No refresh token available')
        }

        try {
            const response = await axios.post('/auth/refresh', {}, {
                headers: {
                    'Refresh-Token': refreshToken.value
                }
            })
            if (response.data && response.data.token && response.data.refresh_token) {
                setTokens(response.data.token, response.data.refresh_token)
                return response.data
            }
            clearTokens()
            throw new Error('Invalid response from server')
        } catch (error) {
            clearTokens()
            throw error
        }
    }

    function logout() {
        clearTokens()
    }

    // Initialize tokens from localStorage only if both exist
    const initialize = () => {
        const storedToken = localStorage.getItem('token')
        const storedRefreshToken = localStorage.getItem('refresh_token')
        if (storedToken && storedRefreshToken) {
            try {
                // Verify token is valid JWT
                const decoded = jwtDecode<JWTPayload>(storedToken)
                const now = Date.now() / 1000
                if (decoded.exp > now) {
                    // Only set tokens if not expired
                    setTokens(storedToken, storedRefreshToken)
                } else {
                    // Clear expired tokens silently
                    clearTokens()
                }
            } catch (error) {
                // Clear invalid tokens silently
                clearTokens()
            }
        }
    }

    // Run initialization
    initialize()

    return {
        token,
        refreshToken,
        user,
        isAuthenticated,
        userName,
        login,
        logout,
        refreshAccessToken,
        register,
        // Expose these for testing
        setTokens,
        clearTokens,
        initialize,
    }
})
