import { defineStore } from 'pinia';
import { ref, onMounted, onUnmounted } from 'vue';

export const useThemeStore = defineStore('theme', () => {
    // Initialize with system preference if available, otherwise default to light theme
    const isDark = ref(false);

    function updateTheme() {
        if (typeof document !== 'undefined') {
            document.documentElement.classList.toggle('dark', isDark.value);
        }
    }

    function handleThemeChange(e: MediaQueryListEvent) {
        isDark.value = e.matches;
        updateTheme();
    }

    // Only run in browser environment
    if (typeof window !== 'undefined') {
        const prefersDark = window.matchMedia?.('(prefers-color-scheme: dark)');

        if (prefersDark) {
            isDark.value = prefersDark.matches;

            // Setup system theme detection
            onMounted(() => {
                prefersDark.addEventListener('change', handleThemeChange);
                updateTheme();
            });

            // Cleanup
            onUnmounted(() => {
                prefersDark.removeEventListener('change', handleThemeChange);
            });
        }
    }

    return {
        isDark
    };
}); 