import axios from 'axios'
import type { InternalAxiosRequestConfig } from 'axios'

// Create axios instance with base URL
const baseURL = process.env.NODE_ENV === 'test'
    ? 'http://localhost:8080/api'
    : `${import.meta.env.VITE_APP_BACKEND_URL || 'http://backend:8080'}/api`

console.log('Using backend URL:', baseURL)
console.log('Axios base URL:', baseURL)

const axiosInstance = axios.create({
    baseURL,
})

// Add request interceptor
axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        // Log the full URL being requested
        const fullUrl = `${config.baseURL || ''}${config.url || ''}`
        console.log('Making request to:', fullUrl)

        // Get token directly from localStorage to avoid circular dependency
        const token = localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        console.error('Request interceptor error:', error)
        return Promise.reject(error)
    }
)

// Add response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config

        // Log error details
        console.error('Response error:', {
            status: error.response?.status,
            url: originalRequest?.url,
            method: originalRequest?.method,
            isRetry: originalRequest?._retry
        })

        // Skip token refresh for login attempts and when refresh is already in progress
        if (
            error.response?.status === 401 &&
            !originalRequest._retry &&
            !originalRequest.url?.includes('/auth/login')
        ) {
            originalRequest._retry = true

            try {
                // Get refresh token directly from localStorage
                const refreshToken = localStorage.getItem('refresh_token')
                if (!refreshToken) {
                    throw new Error('No refresh token available')
                }

                console.log('Attempting token refresh')
                // Try to refresh the token
                const response = await axiosInstance.post('/auth/refresh', {}, {
                    headers: {
                        'Refresh-Token': refreshToken
                    }
                })

                // Update tokens in localStorage
                const { token, refresh_token } = response.data
                localStorage.setItem('token', token)
                localStorage.setItem('refresh_token', refresh_token)

                console.log('Token refresh successful, retrying original request')
                // Retry the original request with new token
                originalRequest.headers.Authorization = `Bearer ${token}`
                return axiosInstance(originalRequest)
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError)
                // Only clear tokens and redirect for actual refresh token failures
                if (axios.isAxiosError(refreshError) && refreshError.response?.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('refresh_token')
                    // Display session expired message
                    const errorMessage = 'Session expired. Please log in again.'
                    // Store the error message in localStorage so it can be displayed on the login page
                    localStorage.setItem('auth_error', errorMessage)
                    window.location.href = '/login'
                }
                return Promise.reject(refreshError)
            }
        }

        return Promise.reject(error)
    }
)

export default axiosInstance 